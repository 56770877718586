import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, take,catchError  } from 'rxjs/operators';
import {throwError} from 'rxjs'
import { NavigationExtras, Router } from "@angular/router";

//primeng services
import { MessageService } from 'primeng/api';
//own services
import { AuthService } from 'src/app/shared/services/auth.service';
//models
import Module from '../models/Module';
import { groupBy, permissionMapper } from '../utils/utils';
import { environment } from 'src/environments/environment';
import { Observable,BehaviorSubject  } from 'rxjs';
import DiasUtilidad from '../models/DIasUtilidad';
import GastoRutaAnual from '../models/GastoRutaAnual';


@Injectable({
    providedIn: 'root'
  })
  export class diasutilidad{

    private apiUrl: string = "";
      private usuariosSubject = new BehaviorSubject<any[]>([]);
      private apiUrlComercial:string="";
    
      constructor(private http: HttpClient, private auth: AuthService, private message: MessageService, private router: Router) {
        this.apiUrl = environment.sUrlAuth + 'Auth';
        this.apiUrlComercial=environment.SUrlgApi+'DiasUtilidad';
       }
    
      public getdiasutilidad():Observable<DiasUtilidad[]>{
        return this.http.get<DiasUtilidad[]>(this.apiUrlComercial+ '/getDiasUtilidad/').pipe(retry(1), catchError(this.handleError));
      }

      public getgastosanual():Observable<GastoRutaAnual[]>{
        return this.http.get<GastoRutaAnual[]>(this.apiUrlComercial+ '/getGastoRutaAnual/').pipe(retry(1), catchError(this.handleError));
      }

       handleError(error:any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
      }
    }
