import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, take,catchError  } from 'rxjs/operators';
import {throwError} from 'rxjs'
import { NavigationExtras, Router } from "@angular/router";

//primeng services
import { MessageService } from 'primeng/api';
//own services
import { AuthService } from 'src/app/shared/services/auth.service';
//models
import Module from '../models/Module';
import { groupBy, permissionMapper } from '../utils/utils';
import { environment } from 'src/environments/environment';
import { Observable,BehaviorSubject  } from 'rxjs';
import BaseDeCobros from '../models/BaseDeCobros';
import BaseDeCobrosDetalle from '../models/BaseDeCobrosDetalle';

@Injectable({
    providedIn: 'root'
  })
  export class basedecobros{

    private apiUrl: string = "";
      private usuariosSubject = new BehaviorSubject<any[]>([]);
      private apiUrlComercial:string="";
    
      constructor(private http: HttpClient, private auth: AuthService, private message: MessageService, private router: Router) {
        this.apiUrl = environment.sUrlAuth + 'Auth';
        this.apiUrlComercial=environment.SUrlgApi+'BaseDeCobros';
       }
    
      public getbasedecobros():Observable<BaseDeCobros[]>{
        return this.http.get<BaseDeCobros[]>(this.apiUrlComercial+ '/getbasedecobros/').pipe(retry(1), catchError(this.handleError));
      }

      public getbasedecobrosdetalle():Observable<BaseDeCobrosDetalle[]>{
        return this.http.get<BaseDeCobrosDetalle[]>(this.apiUrlComercial+ '/getbasedecobrosdetalle/').pipe(retry(1), catchError(this.handleError));
      }

      public editbasedecobros(data: BaseDeCobros ):Observable<any>{
        const headers = { 'content-type': 'application/json'};
        const body = JSON.stringify(data); 
        return this.http.put<any>(this.apiUrlComercial + '/editbasedecobros/',body,{'headers':headers});
       }

       public editbasedecobrosdetalle(data: BaseDeCobrosDetalle ):Observable<any>{
        const headers = { 'content-type': 'application/json'};
        const body = JSON.stringify(data); 
        return this.http.put<any>(this.apiUrlComercial + '/editbasedecobrosdetalle/',body,{'headers':headers});
       }
    
       handleError(error:any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
      }
    }
